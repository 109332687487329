<template>
  <header>
    <div class="header_left">
      <a class="return_back" v-if="showGoback && currentRoute != 'dashboard'">
        <b-icon
          icon="arrow-left-square-fill"
          aria-hidden="true"
          @click="goBack()"
        ></b-icon>
      </a>
      <!--<select id="myselect" class="selectpicker"  data-live-search="true"    >
            <option>Select Client</option>
            <option>Ketchup</option>
            <option>Relish</option>
        </select>
        --->

      <template v-if="showFilter && userdata['roleId'] != 5">
        <multiselect
          v-model="selectedGlobalClient"
          :value="'_id'"
          :options="clientList"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="true"
          :multiple="false"
          label="name"
          placeholder="Select Client"
          track-by="name"
          @search-change="asyncClientList"
          @input="changedGlobalclient"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span
              class="multiselect__selectcustom"
              v-if="values.length && !isOpen"
              >{{ values.length }} selected</span
            >
            <span
              class="multiselect__selectcustom"
              v-if="values.length && isOpen"
            ></span>
          </template>
          <span slot="noResult">Clients not found</span>
        </multiselect>
      </template>
    </div>
    <div class="header_right">
      <ul>
        <!-- <li>
                <img src="@/assets/images/settings.svg" alt="settings" />
            </li>-->
        <!-- <li @click="showNotifications = !showNotifications" class="notifications" :class="{ active: showNotifications }"> -->
        <li class="notifications" :class="{ active: showNotifications }">
          <!-- <img src="@/assets/images/notifications.svg" alt="notifications" /> -->
          <!-- <b-icon icon="bell" aria-hidden="true"></b-icon> -->

          <b-dropdown right dropdown>
            <template #button-content>
              <span v-if="notifyList.length > 0"></span>
              <b-icon icon="bell" aria-hidden="true"></b-icon>
            </template>
            <div class="notifications_sec">
              <div class="notifications_wrap">
                <div class="notifications_title">
                  <label
                    ><template v-if="notifyList.length > 0">{{
                      notifyList.length
                    }}</template
                    >&nbsp;Notifications</label
                  >
                  <em
                    v-if="notifyList.length > 0"
                    @click="onRemoveNotify('removeAll')"
                    >Delete All
                  </em>
                </div>
                <div class="notifyscroll">
                  <div v-slimscroll="scrolloptions">
                    <div
                      class="notifications_list"
                      v-for="(notify, index) in notifyList"
                      :key="index"
                    >
                      <p>{{ notify.description }}</p>
                      <label
                        >{{ notify.createdOn | formatDate
                        }}<em
                          @click="onRemoveNotify(notify._id)"
                          aria-hidden="true"
                          >Delete</em
                        ></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-dropdown>
        </li>
        <li>
          <div class="profile">
            <figure>
              <span><img :src="getProfile" @error="getProfilePhoto"/></span>
              <figcaption v-if="userdata != null">
                {{ userdata.name ? userdata.name : "" }}
              </figcaption>
            </figure>
            <div class="profile_menu">
              <a href="#" @click="showModal('account')">My Account</a>
              <a href="#" @click="showModal('password')">Change Password</a>
              <a href="#" @click="logOut()">Logout</a>
            </div>
          </div>
        </li>
      </ul>

      <b-modal
        ref="my-modal"
        centered
        hide-footer
        hide-header
        class="modal_popup"
      >
        <div class="modal_popup profile_popup">
          <b-button class="close" block @click="hideModal"
            ><span aria-hidden="true">&times;</span></b-button
          >
          <div class="profile_head">
            <div class="profile_title">
              <figure>
                <!--src="@/assets/images/user.jpg" -->
                <img :src="getProfile" @error="getProfilePhoto" />
              </figure>

              <div v-if="apdateAccount" class="profile_edit">
                <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>

                <file-upload
                  v-model="documents"
                  class="file-upload-input"
                  :accept="'.jpg,.jpeg,.png'"
                  :name="'logo'"
                  data-vv-as=" documents"
                  :multiple="false"
                  @input="upload(documents)"
                >
                  <img
                    class="file-icon"
                    src="@/assets/images/upload_icon.svg"
                    @click="documents = []"
                  />
                </file-upload>
              </div>
            </div>

            <div class="profile_details">
              <h5>{{ userdata ? userdata.name : "" }}</h5>
              <p v-if="checkProperty(userdata, 'email')">
                {{ userdata.email }}
              </p>
              <p>{{ userdata ? userdata.roleDetails.name : "" }}</p>
            </div>
          </div>
          <ValidationObserver
            ref="accountForm"
            v-slot="{ invalid, validated, handleSubmit, validate }"
          >
            <form>
              <template v-if="apdateAccount">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">First Name</label>
                      <ValidationProvider
                        name="First Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="payload.fName"
                          class="form-control"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Last Name</label>
                      <ValidationProvider
                        name="Last Name"
                        rules="required|max:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="payload.lName"
                          class="form-control"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Phone Number</label>
                      <ValidationProvider
                        name="Phone Number"
                        rules="required|phonenumber"
                        v-slot="{ errors }"
                      >
                        <VuePhoneNumberInput
                          :id="'account Phone'"
                          default-country-code="US"
                          :no-example="true"
                          :required="true"
                          maxlength="14"
                          v-model="payload.phone"
                          @update="updatePhone"
                          :no-country-selector="true"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Gender</label>
                      <ValidationProvider
                        :name="'Gender'"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <multiselect
                          v-model="selectedGender"
                          :value="'_id'"
                          :options="genderList"
                          :searchable="true"
                          :close-on-select="true"
                          :allow-empty="false"
                          :multiple="false"
                          @input="updateGender"
                          label="name"
                          placeholder="Select Gender"
                          track-by="name"
                        >
                        </multiselect>

                        <span class="v-messages error-msg">{{
                          errors[0] ? "Field is required" : ""
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Old Password</label>
                      <ValidationProvider
                        name="Password"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="password"
                          v-model="passwordData.currentPwd"
                          class="form-control"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">New Password</label>
                      <ValidationProvider
                        name="New Password"
                        rules="required|min:6|max:30"
                        v-slot="{ errors }"
                      >
                        <input
                          type="password"
                          ref="newPassword"
                          v-model="passwordData.newPwd"
                          class="form-control"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Confirm Password</label>
                      <ValidationProvider
                        name="Confirm Password"
                        rules="required|confirmpassword:@New Password"
                        v-slot="{ errors }"
                      >
                        <input
                          type="password"
                          v-model="passwordData.CNFpwd"
                          validate="confirmed:newpassword"
                          class="form-control"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </template>

              <div class="d-flex justify-content-end" v-if="apdateAccount">
                <span
                  class="primary_btn"
                  :disabled="invalid || !validated || checkAccountForm"
                  type="submit"
                  @click="
                    validate();
                    handleSubmit(onSubmit);
                  "
                  >Update</span
                >
              </div>

              <div class="d-flex justify-content-end" v-else>
                <span
                  class="primary_btn"
                  :disabled="invalid || !validated || checkPasswordForm"
                  @click="changePassword()"
                  >Change Password</span
                >
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal
        ref="agrementmodal"
        size="lg"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="agrement-body" @click="errorMsg = ''">
          <template v-if="step == 0">
            <template v-if="agreementHtml != ''">
              <h3>Tax Hero Terms of Service</h3>
              <h6>
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY CLICKING
                “ACCEPTED AND AGREED,” EACH CUSTOMER AND AUTHORIZED USER AGREES
                TO THESE TERMS OF SERVICE
              </h6>
              <p>
                Thank you for selecting the services (the “Service”) offered by
                Tax Hero, Inc. (“we” or “Tax Hero”). This Agreement is a legal
                agreement between you and Tax Hero.
              </p>
              <p>
                By registering for or using the Service, you represent and
                warrant that you have the authority to accept these terms on
                your own behalf, or on behalf of a business if you are
                registering on behalf of a business. If an individual, you
                represent and warrant that you are at least 18 years of age.
              </p>
              <ul class="list_elements">
                <li>
                  <label
                    >License to Use the Service; Ownership of Materials,
                    Software, &amp; IP.</label
                  >
                  <ul class="list-items">
                    <li>
                      <span>1.1</span>Service Use License. Tax Hero hereby
                      grants you a nonexclusive and nontransferable license to
                      access and use the Service, and to download and install
                      mobile applications and other software used to deliver the
                      Service solely during the term of this Agreement and in
                      accordance with this Agreement, and solely for your
                      personal use, or you internal business purposes if you are
                      a business. Tax Hero may, from time to time in the
                      ordinary course of business, refine, modify, or improve
                      current functionality or develop additional
                      functionalities.
                    </li>
                    <li>
                      <span>1.2</span>Reservation of Rights. Tax Hero reserves
                      all rights of ownership in the Service not expressly
                      granted to you herein. The terms “purchase” and “sale” in
                      reference to any software notwithstanding, it is expressly
                      agreed by the parties that title to software provided by
                      Tax Hero does not pass to you. Your rights with respect to
                      software will only be that of a licensee.
                    </li>
                    <li>
                      <span>1.3</span>User Restrictions. You agree not to: (a)
                      permit any third party to access or use the Service; (b)
                      license, sublicense, sell, resell, transfer, assign,
                      frame, mirror or distribute the Service; (c) modify or
                      make derivative works based upon the Service; (d)
                      introduce software or automated agents or scripts to the
                      Service so as to produce multiple accounts, generate
                      automated searches, requests and queries, or to strip,
                      scrape, or mine data from the Service; or (e) copy or
                      reverse engineer the Service or access the Service in
                      order to (i) build a competitive product or service, (ii)
                      build a product using similar ideas, features, functions
                      or graphics of the Service, or (iii) copy any ideas,
                      features, functions or graphics of the Service.
                    </li>
                    <li>
                      <span>1.4</span>Ownership and Sharing of User Content. Tax
                      Hero does not claim ownership of materials you upload to
                      the Service (“User Content”). You grant Tax Hero a
                      worldwide, perpetual, irrevocable, royalty-free and
                      non-exclusive license, to use, distribute, reproduce,
                      modify, adapt, publicly perform and publicly display and
                      otherwise exploit your User Content (User Content
                      submitted or made available for inclusion on the Service
                      (other than Personal Data as defined in the Privacy
                      Policy) on the Service for the purposes of providing and
                      promoting the Service and the features and services
                      available on the Service.
                    </li>
                    <li>
                      <span>1.5</span>Removal of User Content and Service Data.
                      You agree and acknowledge that Tax Hero has no obligation
                      to retain User Content and that such User Content may be
                      irretrievably deleted by Tax Hero on termination of your
                      account.
                    </li>
                    <li>
                      <span>1.6</span>Privacy Policy. Tax Hero collects, uses,
                      and discloses personally identifiable information of its
                      users in accordance with its Privacy Policy located at
                      www.--------.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>License Restrictions.</label>
                  <ul class="list-items">
                    <li>
                      <span>2.1</span>Technical Restrictions. You agree that you
                      will not:
                      <ul class="sublist_letters">
                        <li>
                          Use, employ, or attempt to use or employ any robot,
                          spider, scraper, deep link or other similar automated
                          data gathering or extraction programs, tools,
                          utilities, algorithm or methodology to access,
                          acquire, copy or monitor any portion of the Service,
                          without Tax Hero’s express written consent, which may
                          be withheld in Tax Hero’s sole discretion;
                        </li>
                        <li>
                          Use, employ, or attempt to use or employ any engine,
                          software, tool, agent, or other device or mechanism
                          (including, without limitation robots, spiders,
                          avatars, intelligent agents, or browsers) to navigate
                          or search the Service, other than the search engines
                          and search agents available through the Service and
                          other than generally available third-party web
                          browsers (such as Mozilla Firefox, Microsoft Internet
                          Explorer, or Google Chrome);
                        </li>
                        <li>
                          Post, upload, forward, or otherwise transmit any file
                          or software code which contains, facilitates, or
                          launches viruses, worms, trojan horses or any other
                          contaminating or destructive features, or that
                          otherwise interfere with the proper working of the
                          Service; or
                        </li>
                        <li>
                          Attempt to decipher, decompile, disassemble, or
                          reverse-engineer any of the software comprising or in
                          any way making up a part of the Service.
                        </li>
                        <li>
                          Reproduce, duplicate, copy, deconstruct, sell, trade,
                          resell, frame or mirror the Service.
                        </li>
                        <li>
                          Attempt to access any other Tax Hero systems that are
                          not part of the Service.
                        </li>
                        <li>
                          Excessively overload the Tax Hero systems used to
                          provide the Service.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>2.2</span>Appropriate Use. You agree not to use the
                      Service to upload, post, distribute, link to, publish,
                      reproduce, engage in or transmit any of the following,
                      including but not limited to:
                      <ul class="sublist_letters">
                        <li>
                          Illegal, fraudulent, libelous, defamatory, obscene,
                          pornographic, profane, threatening, abusive, hateful,
                          harassing, offensive, inappropriate or objectionable
                          information or communications of any kind, including
                          without limitation conduct that would encourage or
                          constitute an attack or “flaming” others, or criminal
                          or civil liability under any local, state, federal or
                          foreign law;
                        </li>
                        <li>
                          Content or data that would impersonate someone else or
                          falsely represent your identity or qualifications, or
                          that constitutes a breach of any individual’s privacy,
                          including posting images about children or any third
                          party without their consent (or a parent's consent in
                          the case of a minor);
                        </li>
                        <li>
                          Except as otherwise permitted by Tax Hero in writing,
                          advertisements, solicitations, investment
                          opportunities, chain letters, pyramid schemes, other
                          unsolicited commercial communication or engage in
                          spamming or flooding;
                        </li>
                        <li>
                          Any information, software or Content which is not
                          legally yours and may be protected by copyright or
                          other proprietary right, or derivative works, without
                          permission from the copyright owner or intellectual
                          property rights owner.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>2.3</span>Social Networking. The Service may include
                      social networking features to exchange information with
                      other users of the Service and the public. When you
                      interact with other users: (a) please use respect; and (b)
                      do not reveal information in the social networking
                      features that you do not want to make public. Also be
                      aware that (a) Tax Hero does not support and is not
                      responsible for the accuracy of others’ content in these
                      features, and (b) users may post hypertext links to
                      content hosted and maintained by third parties for which
                      Tax Hero is not responsible.
                    </li>
                    <li>
                      <span>2.4</span>Monitoring of Content. Tax Hero may, but
                      has no obligation to, monitor content on the Service. We
                      may disclose any information necessary or appropriate to
                      satisfy our legal obligations, protect Tax Hero or its
                      customers, or operate the Service properly. Tax Hero, in
                      its sole discretion, may refuse to post, remove, or refuse
                      to remove, any Content, in whole or in part, alleged to be
                      unacceptable, undesirable, inappropriate, or in violation
                      of this Agreement.
                    </li>
                    <li>
                      <span>2.5</span>General Practices. You acknowledge that
                      Tax Hero may establish general practices and limits
                      concerning the use of the Service, including without
                      limitation Tax Hero’s policy on retention and deletion of
                      Content. You further acknowledge and agree that Tax Hero
                      reserves the right to change these general practices and
                      limits at any time, in its sole discretion, with or
                      without notice.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>Account Security</label>
                  <ul class="list-items">
                    <li>
                      <span>3.1</span>You are responsible for maintaining the
                      privacy and security of your password and other security
                      information. You are also responsible for maintaining the
                      integrity of your computer, mobile phone or other internet
                      device to prevent theft, viruses, spyware or other
                      malicious software from stealing your password information
                      or data. Tax Hero bears no responsibility for unauthorized
                      access to your account as a result of your failure to
                      follow appropriate security precautions, including
                      changing your password promptly upon discovery of any
                      security issue.
                    </li>
                    <li>
                      <span>3.2</span>If you should become aware of any
                      unauthorized use of your account or user information, or
                      if you otherwise believe that your account has been
                      compromised, you agree to immediately notify Tax Hero
                      through our feedback form at
                      http://www.----------------.com/feedback
                      <label
                        >If you should become aware of any unauthorized use of
                        your account or user information, or if you otherwise
                        believe that your account has been compromised, you
                        agree to immediately notify Tax Hero through our
                        feedback form at
                        http://www.----------------.com/feedback
                      </label>
                      We may offer to you other services, features, products,
                      applications, online communities, or promotions provided
                      by Tax Hero. If you decide to use any of these other
                      services, additional terms and conditions and separate
                      fees may apply. If you decide to use any third party
                      products or access any third party sites, you are
                      responsible for reviewing the third party’s separate
                      product terms, website terms and privacy policies.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>Payment</label>
                  <ul class="list-items">
                    <li>
                      <span>4.1</span>Payment Terms. If you use a paid version
                      of the Service, you will pay Tax Hero fees for the Service
                      as set forth on your account profile. All charges are
                      exclusive of taxes and similar charges. You will be
                      responsible for all sales, use, value-added or other taxes
                      resulting from services rendered under this Agreement
                      other than taxes based on Tax Hero’s income and except to
                      the extent you are a tax-exempt entity. A late fee of one
                      and one-half percent (1.5%) per month or the highest rate
                      allowed under the law, whichever is lower, shall be
                      assessed against overdue amounts.
                    </li>
                    <li>
                      <span>4.2</span>Payment Methods. You must pay with one of
                      the following:
                      <ul class="sublist_letters">
                        <li>
                          A valid credit or debit card acceptable to Tax Hero;
                        </li>
                        <li>
                          Sufficient funds in a checking or savings account to
                          cover an electronic debit of the payment due; or
                        </li>
                        <li>
                          By any other payment option made available by Tax
                          Hero.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>4.3</span>If your payment and registration
                      information are not accurate, current, and complete and
                      you do not notify us promptly when such information
                      changes, we may suspend or terminate your account and
                      refuse any use of the Service.
                    </li>
                  </ul>
                </li>
                <li>
                  <label
                    >Disclaimer Of Warranties; Limitation Of Liability
                  </label>
                  <ul class="list-items">
                    <li>
                      <span>5.1</span>AS IS WARRANTY. YOUR USE OF THE SERVICE IS
                      ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS
                      AGREEMENT, THE SERVICE IS PROVIDED “AS IS” TO THE MAXIMUM
                      EXTENT PERMITTED BY APPLICABLE LAW. Tax Hero, ITS
                      AFFILIATES, PARTNERS AND ITS THIRD PARTY SERVICE
                      PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS
                      (COLLECTIVELY “AGENTS”) DISCLAIM ALL WARRANTIES, EXPRESS
                      OR IMPLIED, INCLUDING ANY WARRANTY THAT THE SERVICE IS FIT
                      FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA
                      LOSS, UPTIME OR CONTINUITY OF THE SERVICE,
                      NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY
                      INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY,
                      RELIABILITY, QUALITY OR CONTENT OF INFORMATION IN OR
                      LINKED TO THE SERVICE. Tax Hero AND ITS AGENTS DO NOT
                      WARRANT THAT THE SERVICE IS SECURE, FREE FROM BUGS,
                      VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF
                      THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU,
                      ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE
                      DATE OF PURCHASE OR DELIVERY OF THE SERVICE, WHICHEVER IS
                      SOONER.
                    </li>
                    <li>
                      <span>5.2</span>LIMITATION OF LIABILITY. TO THE MAXIMUM
                      EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY
                      OF Tax Hero AND ITS AGENTS FOR ALL MATTERS OR CLAIMS
                      RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE GREATER
                      OF THE AMOUNT YOU PAID TO Tax Hero FOR THE SERVICE DURING
                      THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM AND $100. Tax
                      Hero AND ITS AGENTS ARE NOT LIABLE FOR ANY OF THE
                      FOLLOWING: (A) ANY AND ALL INDIRECT, SPECIAL, INCIDENTAL,
                      PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) ANY AND ALL DAMAGES
                      INDIVIDUALS INCUR AS A RESULT OF OUR EFFORTS TO MATCH THEM
                      WITH APPROPRIATE FACILITIES, (C) LOSSES OR INJURIES
                      INDIVIDUALS OR FACILITIES MAY SUFFER RESULTING FROM ANY
                      PROFESSIONAL SERVICES FACILITATED BY THE SERVICE; OR (E)
                      OR ANY OTHER INTERACTION BETWEEN YOU AND ANY THIRD PARTIES
                      AS A RESULT OF YOUR USE OF THE SERVICE. THE ABOVE
                      LIMITATIONS APPLY EVEN IF Tax Hero AND ITS AGENTS HAVE
                      BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS
                      AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF Tax Hero AND
                      ITS AFFILIATES, PARTNERS AND SUPPLIERS AS WELL AS YOUR
                      EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICE AND ITS USE
                      AND YOUR AGREEMENT TO THESE PROVISIONS AND LIMITATIONS IS
                      A MATERIAL INDUCEMENT TO Tax Hero TO ENTER INTO THIS
                      AGREEMENT WITH YOU AND PROVIDE YOU ACCESS TO THE SERVICE
                      AND OTHER OFFERINGS PROVIDED BY Tax Hero.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>Indemnification</label>
                  <ul class="list-items">
                    <li>
                      You agree to indemnify and hold Tax Hero and its Agents
                      harmless from any and all claims, liability and expenses,
                      including reasonable attorney's fees and costs made by
                      third parties and, arising out of your use of the Service
                      or breach of this Agreement (collectively referred to as
                      “Claims”). Tax Hero reserves the right, in its sole
                      discretion and at its own expense, to assume the exclusive
                      defense and control of any Claims. You agree to reasonably
                      cooperate as requested by Tax Hero in the defense of any
                      Claims.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>Termination</label>
                  <ul class="list-items">
                    <li>
                      <span>7.1</span>By Tax Hero. Tax Hero may immediately and
                      without notice terminate this Agreement or suspend the
                      Service provided to you if you fail to comply with these
                      terms. Upon termination you must immediately stop using
                      the Service. Our Privacy Policy and sections 1.2, 1.4, 2,
                      5, 6 of this Agreement will survive and remain in effect
                      even if the Agreement is terminated, canceled or
                      rescinded.
                    </li>
                    <li>
                      <span>7.2</span>By You. You may cancel your account or any
                      part of the Service at any time; provided that Tax Hero
                      will not refund any pre-paid fees upon such termination or
                      cancellation. Tax Hero reserves the right to collect fees,
                      surcharges or costs incurred before you cancel your
                      account in addition to any applicable cancellation fee(s).
                    </li>
                    <li>
                      <span>7.3</span>Effect of Termination. Upon termination of
                      your account for any reason, Tax Hero reserves the right
                      to (a) collect all charges, fees, commitments and
                      obligations incurred or accrued by you; (b) delete any of
                      your Content, listings, messages or other information in
                      connection with your account; (c) prohibit your access to
                      your account, including without limitation by deactivating
                      your password; and (d) refuse your future access to the
                      Service.
                    </li>
                  </ul>
                </li>
                <li>
                  <label>Miscellaneous</label>
                  <ul class="list-items">
                    <li>
                      <span>8.1</span>Changes To This Agreement Or The Service.
                      Tax Hero may amend these Terms from time to time by
                      posting an amended version at its website and sending
                      written notice thereof to you. Such amendment will be
                      deemed accepted and become effective 30 days after such
                      notice (the “Proposed Amendment Date”) unless you first
                      give Tax Hero written notice of rejection of the
                      amendment. In the event of such rejection, if you have a
                      paid account this Agreement will continue under its
                      original provisions, and the amendment will become
                      effective at the start of Customer’s next Term following
                      the Proposed Amendment Date (unless you first terminates
                      this Agreement as set forth herein). If you have a free
                      account your sole recourse is to terminate your account
                      and cease using the Service. Continued use of the Service
                      following Proposed Amendment Date shall constitute your
                      acceptance of such changes. Tax Hero reserves the right to
                      modify, suspend, or discontinue the Service with or
                      without notice. Tax Hero will notify you via the Service
                      of any significant updates thereto. Tax Hero shall not be
                      liable should Tax Hero exercise its right to modify,
                      suspend, or discontinue the Service. Tax Hero may amend
                      the Privacy Policy at any time as set forth therein.
                    </li>
                    <li>
                      <span>8.2</span>Governing Law And Jurisdiction. The
                      internal, substantive laws of the State of Texas govern
                      this Agreement without regard to its conflicts of laws
                      provisions. The United Nations Convention on Contracts for
                      the International Sale of Goods does not apply to this
                      Agreement. As a material inducement to Tax Hero to enter
                      into this Agreement and provide you access to the Service,
                      you agree to the exclusive jurisdiction of state or
                      federal courts located in Houston, Texas for any legal
                      proceedings arising from your use of the Service. Tax Hero
                      does not represent that information on the website for the
                      Service is appropriate or available for use in all
                      countries. Tax Hero prohibits accessing materials from
                      countries or states where contents are illegal. You are
                      accessing this website on your own initiative and you are
                      responsible for compliance with all applicable laws.
                    </li>
                    <li>
                      <span>8.3</span>Integration. This Agreement is the entire
                      agreement and understanding between you and Tax Hero and
                      replaces and supersedes all prior understandings,
                      communications, commitments, and agreements, oral or
                      written, regarding its subject matter.
                    </li>
                    <li>
                      <span>8.4</span>Assignment. You may assign this Agreement
                      only with the written consent of Tax Hero, which may not
                      be unreasonably withheld.
                    </li>
                    <li>
                      <span>8.5</span>Enforceability. If any court of competent
                      jurisdiction rules that any part of this Agreement is
                      invalid, illegal, void or unenforceable, then such part of
                      this Agreement will be removed and severed from this
                      Agreement without affecting the validity, enforceability,
                      and effectiveness of the remainder of this Agreement. The
                      remaining terms will be valid and enforceable. Tax Hero’s
                      right and remedies under this Agreement are intended to be
                      cumulative and any failure by Tax Hero to exercise or
                      enforce any of its rights or remedies under or in
                      connection with this Agreement or to which Tax Hero is
                      entitled under any applicable law, will not constitute or
                      be taken or construed as a waiver of any of Tax Hero’s
                      rights or remedies, all of which will still be and remain
                      available to Tax Hero.
                    </li>
                  </ul>
                </li>
              </ul>
              <div>
                <div class="col">
                  <input
                    class="styled-checkbox"
                    :id="'agrement'"
                    v-model="isAgreed"
                    type="checkbox"
                    :value="isAgreed"
                  />
                  <label :for="'agrement'">I Agree</label>
                </div>
                <span class="v-messages error-msg" v-if="errorMsg">
                  {{ errorMsg }}
                </span>
                <div class="d-flex justify-content-end">
                  <button
                    class="primary_btn"
                    type="button"
                    :disabled="!isAgreed"
                    @click="agrementNextStep()"
                  >
                    OK
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-html="agreementHtml"></div>
              <div>
                <div class="col">
                  <input
                    class="styled-checkbox"
                    :id="'agrement'"
                    v-model="isAgreed"
                    type="checkbox"
                    :value="isAgreed"
                  />
                  <label :for="'agrement'">I Agree</label>
                </div>
                <span class="v-messages error-msg" v-if="errorMsg">
                  {{ errorMsg }}
                </span>
                <div class="d-flex justify-content-end">
                  <button
                    class="primary_btn"
                    type="button"
                    :disabled="!isAgreed"
                    @click="agrementNextStep()"
                  >
                    I Agree
                  </button>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <h3 class="popup_title">
              <a class="return_back">
                <b-icon
                  icon="arrow-left-square-fill"
                  aria-hidden="true"
                  @click="step = 0"
                ></b-icon>
              </a>
              Authorization
            </h3>

            <div v-html="pricingModelhtml"></div>

            <div class="row">
              <div class="col">
                <input
                  class="styled-checkbox"
                  :id="'agrement'"
                  v-model="authorize"
                  type="checkbox"
                  :value="authorize"
                />
                <label :for="'agrement'">Authorize</label>
              </div>
              <span class="v-messages error-msg" v-if="errorMsg">
                {{ errorMsg }}
              </span>
              <div class="d-flex justify-content-end">
                <button
                  class="primary_btn"
                  type="button"
                  :disabled="!authorize"
                  @click="acceptAgrement()"
                >
                  Authorize
                </button>
              </div>
            </div>
          </template>
        </div>
      </b-modal>

      <!-- <h3 @click="toglelicenseAgrement(true)">Click Me


 </h3> -->
    </div>

    <div
      class="notifications_wrap"
      :class="{ active: showNotifications }"
      style="display:none"
    >
      <div
        class="notifications_layer"
        @click="showNotifications = !showNotifications"
      ></div>
      <div class="notifications_list">
        <div class="notifications_title">
          <label>Notifications</label>
          <span
            v-if="notifyList.length > 0"
            @click="onRemoveNotify('removeAll')"
            >Remove All
          </span>
        </div>

        <ul>
          <!-- <li v-for="(notify ,index) in notifyList" :key="index" >
                        <p>{{notify.description}}</p>
                        <span>{{notify.createdOn | formatDate}}</span>
                        <em><b-icon icon="x-circle" @click="onRemoveNotify(notify._id)" aria-hidden="true"></b-icon></em>
                    </li> -->
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Multiselect from "vue-multiselect-inv";
import _ from "lodash";
import FileUpload from "vue-upload-component/src";

export default {
  name: "LNA",
  watch: {
    showNotifications: (val) => {
      const el = document.body;

      if (val) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    },
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    showGoback: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
    Multiselect,
    FileUpload,
  },
  methods: {
    getTermsAndPricingContent() {
      this.agreementHtml = "";
      this.$store.dispatch("auth/getTermsAndPricingContent").then((res) => {
        if (_.has(res, "termsAndConditions")) {
          this.agreementHtml = res["termsAndConditions"];
        }
      });
    },
    getClientdetails() {
      this.$store
        .dispatch("getClientdetails", { clientId: this.clientId })
        .then((res) => {
          if (_.has(res, "termsAccepted") && res["termsAccepted"] == false) {
            if (_.has(res, "pricingModel") && res["pricingModel"]) {
              this.pricingModelhtml = res["pricingModel"];
            }

            this.toglelicenseAgrement(true);
          }
        });
    },

    agrementNextStep() {
      this.authorize = false;
      this.step++;
    },
    acceptAgrement() {
      this.errorMsg = "";
      if (this.isAgreed && this.authorize) {
        let payLoad = { termsAccepted: true, pricingModelAccepted: true };
        this.$store
          .dispatch("auth/updateTermsAndCondation", payLoad)
          .then((res) => {
            this.testData = JSON.parse(localStorage.getItem("user"));

            this.toglelicenseAgrement(false);
            this.notify({ message: res, title: "Success", type: "success" });
            this.getClientdetails();
          })
          .catch((error) => {
            console.log(error);
            this.errorMsg = error;
            // this.notify({message:error ,title:'Error' ,type:"error"});
          });
      } else {
        this.isAgreed = false;
        this.authorize = false;
        this.step = 0;
        this.errorMsg = "";
      }
    },

    toglelicenseAgrement(actioon = false) {
      if (actioon == true) {
        this.$refs["agrementmodal"].show();
      } else {
        this.$refs["agrementmodal"].hide();
      }
    },
    setGlClient(item) {
      this.selectedGlobalClient = item;
      this.changedGlobalclient();
    },

    changedGlobalclient() {
      let client = this.selectedGlobalClient;
      if (
        _.has(this.selectedGlobalClient, "_id") &&
        this.selectedGlobalClient["_id"] == null
      ) {
        client = null;
      }
      this.$store.dispatch("globalClient", client);
      localStorage.setItem("selectedGlobalClient", JSON.stringify(client));
      this.$emit("changedGlobalclient", client);
      /*
           if(this.$route.name.trim() == "questionnaire"){
                if(  _.has(this.selectedGlobalClient ,"_id") && this.selectedGlobalClient['_id'] !=null && this.selectedGlobalClient['_id'] !='null'){

                     this.$router.go('/questionnaire/'+this.selectedGlobalClient["_id"]);

                }
           }else{

           }
           */
    },
    asyncClientList(query) {
      //this.clientList = [];
      if (query != "") {
        this.searchText = query;
        this.getCliList();
      }
    },

    getCliList() {
      let payLoad = {
        matcher: {
          searchString: this.searchText,
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [231], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 1000,
        category: "client_list", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store.dispatch("getMaterData", payLoad).then((res) => {
        this.clientList = [];
        this.clientList.push({ _id: null, name: "All Clients" });
        _.forEach(res.list, (item) => {
          this.clientList.push(item);
        });
      });
    },

    goBack() {
      this.$router.go(-1);
    },
    upload(model) {
      let temp_count = 0;
      let formData = new FormData();

      let mapper = model.map(
        (item) =>
          (item = {
            name: item.name,
            file: item.file ? item.file : null,
            path: item.url ? item.url : "",
            url: item.url ? item.url : "",
            extn: item.name.split(".").pop(),
            mimetype: item.type ? item.type : item.mimetype,
          })
      );

      //var ext = mapper[0].extn.split('.').pop();

      if (mapper.length > 0) {
        mapper.forEach((doc, index) => {
          formData.append("files", doc.file);
          formData.append("secureType", "public");
          this.$store.dispatch("uploadS3File", formData).then((response) => {
            temp_count++;

            response.data.result.forEach((urlGenerated) => {
              if (_.has(urlGenerated, "path") && !_.has(urlGenerated, "url")) {
                urlGenerated["url"] = urlGenerated["path"];
              }
              if (_.has(urlGenerated, "url") && !_.has(urlGenerated, "path")) {
                urlGenerated["path"] = urlGenerated["url"];
              }

              this.payload["photo"] = urlGenerated["path"];
              try {
                this.userdata["photo"] = _.cloneDeep(urlGenerated["path"]);
              } catch (err) {
                this.log(err);
              }

              if (temp_count >= mapper.length) {
                this.documents = [];
              }

              doc.url = urlGenerated;
              delete doc.file;
              mapper[index] = doc;
            });
          });
        });
        model.splice(0, mapper.length, ...mapper);
      }
    },

    updateGender(item) {
      //alert(JSON.stringify(item));
      this.payload.gender = item._id;
    },
    updatePhone(item) {
      if (item.isValid) {
        this.payload.phoneCode = item.countryCallingCode;
        this.payload.phone = item.nationalNumber;
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.go("/");
    },
    showModal(type = "account") {
      if (type == "account") {
        this.apdateAccount = true;
      } else {
        this.apdateAccount = false;
      }
      this.$refs["my-modal"].show();
      this.passwordData = { newPwd: "", currentPwd: "", CNFpwd: "" };
      // this.payload = { fName: this.userdata.fName, lName : this.userdata.lName, phone: this.userdata.phone, phoneCode: this.userdata.phoneCode, gender: this.userdata.gender};
      if (_.has(this.userdata, "fName")) {
        this.payload.fName = this.userdata.fName;
      }
      if (_.has(this.userdata, "lName")) {
        this.payload.lName = this.userdata.lName;
      }
      if (_.has(this.userdata, "phone")) {
        this.payload.phone = this.userdata.phone;
      }
      if (_.has(this.userdata, "phoneCode")) {
        this.payload.phoneCode = this.userdata.phoneCode;
      }
      if (_.has(this.userdata, "photo")) {
        this.payload.photo = this.userdata.photo;
      }
      if (_.has(this.userdata, "gender")) {
        let g = _.find(this.genderList, { _id: this.userdata.gender });

        if (g) {
          this.payload.gender = g["_id"];
          this.selectedGender = g;
        }
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    onSubmit() {
      let payloadData = this.payload;
      //  payloadData.phone = this.userdata.phone
      // payloadData.phoneCode = this.userdata.phoneCode
      // payloadData.gender = this.userdata.gender
      payloadData.userId = this.userdata.userId;
      this.$store.dispatch("updateProfile", payloadData).then(
        (result) => {
          //alert(JSON.stringify(result.data.message))
          //this.response = result.data.message;
          //this.success_msg = result;
          this.notify({
            message: result.data.message,
            title: "Success",
            type: "success",
          });

          let user = _.cloneDeep(this.userdata);
          user["phone"] = this.payload["phone"];
          user["phoneCode"] = this.payload["phoneCode"];
          user["lName"] = this.payload["lName"];
          user["fName"] = this.payload["fName"];
          user["name"] = this.payload["fName"] + " " + this.payload["lName"];
          user["gender"] = this.payload["gender"];
          user["photo"] = this.payload["photo"];
          this.userdata = _.cloneDeep(user);
          this.$store.dispatch("auth/updateuserData", this.userdata);
          this.hideModal();
          //this.userdata = this.$store.getters['auth/getuser'];
          this.userdata = _.cloneDeep(JSON.parse(localStorage.getItem("user")));
        },
        (error) => {
          //alert(JSON.stringify(error))
          this.error_msg = error;
          this.notify({
            message: this.error_msg,
            title: "Error",
            type: "error",
          });
        }
      );
    },
    changePassword() {
      if (!this.checkPasswordForm) {
        this.$store.dispatch("changePassword", this.passwordData).then(
          (result) => {
            // alert();
            //alert(JSON.stringify(result.data.message))
            //this.response = result.data.message;
            //this.success_msg = result;
            this.hideModal();
            this.notify({
              message: result.data.message,
              title: "Success",
              type: "success",
            });
          },
          (error) => {
            //alert(JSON.stringify(error))
            this.error_msg = error;
            this.notify({
              message: this.error_msg,
              title: "Error",
              type: "error",
            });
          }
        );
      }
    },
    getNotifyList() {
      let postData = { page: 1, perpage: 10 };
      this.$store
        .dispatch("getNotifyList", postData)
        .then((response) => {
          console.log(response);
          this.notifyList = response.list;
          this.notifyListTotalCount = response.totalCount;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    onRemoveNotify(item) {
      let payloadData = {};
      if (item === "removeAll") {
        payloadData = {
          removeAll: true,
        };
      } else {
        payloadData = {
          notifyId: item,
        };
      }

      this.$store
        .dispatch("removeNotify", payloadData)
        .then((result) => {
          this.getNotifyList();
          this.notify({
            message: result.data.message,
            title: "Success",
            type: "success",
          });
        })
        .catch((err) => {
          this.log(err);
        });
    },
  },

  data: () => ({
    agreementHtml: "",
    testData: null,
    pricingModelhtml: "",
    step: 0,
    isAgreed: false,
    authorize: false,
    searchText: "",
    selectedGlobalClient: { _id: null, name: "All Clients" },
    clientList: [],
    notifyList: [],
    showNotifications: false,
    notifyListTotalCount: 0,
    userdata: null,
    documents: [],
    payload: {
      fName: "",
      lName: "",
      phone: "",
      phoneCode: "",
      gender: "",
      photo: "",
    },
    passwordData: { newPwd: "", currentPwd: "", CNFpwd: "" },
    apdateAccount: true,
    selectedGender: null,
    genderList: [
      { _id: "M", name: "Male" },
      { _id: "F", name: "Female" },
      { _id: "O", name: "Others" },
    ],
    scrolloptions: {
      height: "100%",
    },
    errorMsg: "",
    clientId: null,
  }),
  mounted() {
    this.getTermsAndPricingContent();
    this.pricingModelhtml = `
                <p>51 - 10,000 monthly orders: $0.10 and 10,001+ orders = $1,000 per month</p>
                <p>51 - 10,000 monthly orders: $0.15 and 10,001+ orders = $1,500 per month</p>
                <p>51 - 10,000 monthly orders: $0.20 and 10,001+ orders = $2,000 per month</p>
                <p>51 - 10,000 monthly orders: $0.25 and 10,001+ orders = $2,500 per month</p>
       `;

    //getuser
    // this.testData=  this.$store.getters['auth/getuser'];
    this.testData = JSON.parse(localStorage.getItem("user"));

    if (_.has(this.testData, "clientDetails")) {
      this.clientId = this.testData.clientDetails._id;
      this.getClientdetails();
    }

    this.step = 0;

    this.userdata = this.$store.getters["auth/getuser"];

    //     if((!this.$store.getters['auth/getclientAgrementStatus'] ||   !localStorage.getItem('termsAccepted' )) && this.$store.getters['auth/getuserRole'] ==5){

    //       this.toglelicenseAgrement(true)

    //   }

    //this.userdata = localStorage.getItem('user');
    this.genderList = [
      { _id: "M", name: "Male" },
      { _id: "F", name: "Female" },
      { _id: "O", name: "Others" },
    ];
    if (_.has(this.userdata, "lName")) {
      this.payload.lName = this.userdata.lName;
    }

    if (_.has(this.userdata, "fName")) {
      this.payload.fName = this.userdata.fName;
    }
    if (_.has(this.userdata, "photo")) {
      this.payload.photo = this.userdata.photo;
    }

    if (_.has(this.userdata, "gender")) {
      this.selectedGender = _.find(this.genderList, {
        _id: this.userdata["gender"],
      }) || null;
    }
    this.getNotifyList();
    this.getCliList();
    //alert( JSON.stringify(this.userdata));

    if (
      this.$store.getters["getGlobalClient"] &&
      this.$store.getters["getGlobalClient"] != null
    ) {
      this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
      this.changedGlobalclient();
    } else if (
      localStorage.getItem("selectedGlobalClient") &&
      localStorage.getItem("selectedGlobalClient") != null &&
        localStorage.getItem("selectedGlobalClient") != "null"
    ) {
      this.selectedGlobalClient = JSON.parse(
        localStorage.getItem("selectedGlobalClient")
      );
      //alert(JSON.stringify(this.selectedGlobalClient));
      this.changedGlobalclient();
    } else {
      this.selectedGlobalClient = { _id: null, name: "All Clients" };
    }
    // this.toglelicenseAgrement(true); getclientAgrementStatus
  },
  computed: {
    checkAgreement() {
      return this.$store.getters["auth/getclientAgrementStatus"];
    },

    getProfile() {
      if (_.has(this.userdata, "photo")) {
        return this.userdata["photo"];
      } else {
        return "";
      }
    },

    checkAccountForm() {
      // console.log(JSON.stringify(this.payload));
      if (
        this.payload.lName == "" ||
        this.payload.fName == "" ||
        this.payload.phone == "" ||
        this.payload.phoneCode == null || this.payload.phoneCode == "" ||
        this.payload.gender == null || this.payload.gender == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkPasswordForm() {
      //passwordData:{"newPwd":"","currentPwd":"", "CNFpwd": ""},
      if (
        this.passwordData.currentPwd == null ||
        this.passwordData.currentPwd == "" ||
        this.passwordData.newPwd == null || this.passwordData.newPwd == "" ||
        this.passwordData.CNFpwd == null || this.passwordData.CNFpwd == "" ||
        this.passwordData.newPwd != this.passwordData.CNFpwd
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeCreate() {
    // this.userdata = this.$store.getters['auth/getuser'];
    this.userdata = _.cloneDeep(JSON.parse(localStorage.getItem("user")));
  },
};
</script>
